import React from "react"
import { navigate } from "gatsby"
import { Router } from "@reach/router"
import AdminPage from "../components/authSections/adminPages/view"
import PrivateRoute from "../components/authSections/privateRoute"

const Redirect = props => {
  if (typeof window !== "undefined") {
    navigate("/app/login")
  }
  return null
}
const Index = props => {
  return props.children
}
{
  /* <Redirect path="/" default /> */
}

const Admin = props => {
  return (
    <Router>
      <Index path="/administorial_section">
        <PrivateRoute
          path="/"
          appPath={props.location.pathname}
          component={AdminPage}
          legacy={false}
          isAdmin={true}
        />
        <AdminPage path="/" />
      </Index>
    </Router>
  )
}

export default Admin
